import createReactClass  from 'create-react-class';
import React, {Fragment} from 'react';

import Parallax from "./react-components/Parallax";
import ParallaxSection from "./react-components/ParallaxSection";
import ListBlock from "./ListBlock";

import ContactFooter from "./ContactFooter";

export default createReactClass({

    render: function(){
        return (
            <div>
                <Parallax src={"cubes_1.jpg"} content={<div style={{color: "white", marginTop: "200px"}} >
                    {window.smallWidthDisplay() ? <span className="title-background-black" style={{maxWidth: "90%"}}>
                        <h2 className="m-20">JCA-COM</h2>
                        <h3 className="m-20">Développement web et mobile</h3>
                    </span> : <span className="title-background-black">
                        <h1 className="m-20">JCA-COM</h1>
                        <h2 className="m-20">Développement web et mobile</h2>
                    </span>}
                </div>} />

                <ParallaxSection content={
                    <fragment>
                        <div className="paragraph-title">
                            {window.smallWidthDisplay() ? <div>
                                <h3>Votre communication digitale</h3>
                                <h5>Digitalisez votre présence avec nos solutions en ligne sur-mesure</h5>
                            </div> : <div>
                                <h2>Votre communication digitale</h2>
                                <h4>Digitalisez votre présence avec nos solutions en ligne sur-mesure</h4>
                            </div>}
                        </div>
                        <div className={window.smallWidthDisplay() ? "tm-30" : "tm-80"}>
                            <ListBlock src={"icons/tablet.png"}
                                       title={"Site vitrine"}
                                       text={<div>
                                                <div className="bm-10">Besoin d'une présentation simple et efficace de votre activité ?</div>
                                                <div>Nous vous proposons un site vitrine à votre image présentant vos services.</div>
                                       </div>}
                            />
                            <ListBlock src={"icons/e_commerce.png"}
                                       title={"E-Commerce"}
                                       text={<div>
                                           <div className="bm-10">Envie de proposer vos produits en ligne facilement ?</div>
                                           <div>Optez pour une solution e-commerce que nous vous développerons sur-mesure.</div>
                                       </div>}
                            />
                            <ListBlock src={"icons/restauration.png"}
                                       title={"Restauration"}
                                       text={<div>
                                           <div className="bm-10">Vous souhaitez optimisez vos réservations et livraisons ?</div>
                                           <div>Nous vous ferons découvrir le potentiel des applications web et mobile dans ce domaine.</div>
                                       </div>}
                            />
                        </div>
                    </fragment>
                } />
                <Parallax src={"particles.jpg"} content={<div style={{color: "white", marginTop: "100px"}} >
                    {window.smallWidthDisplay() ? <span className="title-background-white" style={{maxWidth: "90%"}}>
                        <h2 className="m-20">Nos atouts</h2>
                        <h4 className="m-20">Nous mettons tout notre savoir-faire et notre passion des technologies nouvelles à votre service</h4>
                    </span> : <span className="title-background-white" style={{maxWidth: "75%"}}>
                        <h1 className="m-20">Nos atouts</h1>
                        <h2 className="m-20">Nous mettons tout notre savoir-faire et notre passion des technologies nouvelles à votre service</h2>
                    </span>}
                </div>} />
                <ParallaxSection content={
                    <fragment>
                        <div className="paragraph-title">
                            {window.smallWidthDisplay() ? <span style={{maxWidth: "90%"}}>
                                <h3>Plus qu'une simple application</h3>
                                <h5>C'est un ensemble de services et d'accompagnement qui vous permet de déployer votre potentiel dans le monde digital</h5>
                            </span> : <span style={{maxWidth: "75%"}}>
                                <h2>Plus qu'une simple application</h2>
                                <h4>C'est un ensemble de services et d'accompagnement qui vous permet de déployer votre potentiel dans le monde digital</h4>
                            </span>}
                        </div>
                        <div className={window.smallWidthDisplay() ? "tm-30" : "tm-80"}>
                            <ListBlock src={"icons/responsive_2.png"} iconHeight={180}
                                       title={"Sur-mesure et flexible"}
                                       text={<div>
                                           <div className="bm-10">Nous sommes à votre écoute pour développer une solution qui répond à tous vos besoins.</div>
                                           <div>Le résultat est une application multi-plateforme : web, tablettes et mobiles (Android & iOS)</div>
                                       </div>}
                            />
                            <ListBlock src={"icons/multi_tools.png"} iconHeight={180}
                                       title={"Des outils pour vous aider"}
                                       text={<div>
                                           <div className="bm-10">Nous mettons à votre disposition une panoplie d'outils vous permettant de gérer au mieux votre digitalisation.</div>
                                           <div>Statistiques de visite et d'utilisation, communication automatisée avec les clients (notifications, e-mail, SMS) et plus encore!</div>
                                       </div>}
                            />
                            <ListBlock src={"icons/phone_support.png"} iconHeight={180}
                                       title={"Un support efficace"}
                                       text={<div>
                                          <div className="bm-10">Nous vous proposons un support rapide, efficace et à votre écoute pour vous aider en cas de problème.</div>
                                          <div>Par téléphone ou e-mail, nous sommes là pour répondre à vos attentes.</div>
                                       </div>}
                            />
                        </div>
                    </fragment>
                } />
                <ContactFooter onContactClick={this.props.onContactClick} />
            </div>
        )
    }

});