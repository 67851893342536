import React from "react";

let iconSrc = require.context('../../images', true);

export default class extends React.Component {
    render(){
        let c_style = Object.assign({}, content_style);
        return (
            <div className="parallax-container" style={{height: this.props.height ? this.props.height + "px" : undefined}}>
                <div className="parallax">
                    <img src={iconSrc("./" + this.props.src)} />
                    <div style={c_style}>{this.props.content}</div>
                </div>
            </div>
        )
    }
}

let content_style = {
    position: "relative",
    textAlign: "center"
};