import createReactClass  from 'create-react-class';
import React, {Fragment} from 'react';

import Parallax from "./react-components/Parallax";
import ParallaxSection from "./react-components/ParallaxSection";
import ListBlock from "./ListBlock";

export default createReactClass({

    render: function(){
        return (
            <div>
                <Parallax src={"geometric.jpg"} content={<div style={{color: "white", marginTop: "200px"}} >
                    <span className="title-background-black">
                        <h1 className="m-20">Contact</h1>
                    </span>
                </div>} />
                <ParallaxSection content={
                    <fragment>
                        {window.smallWidthDisplay() ? <div className="paragraph-title">
                            <h3>Nous sommes à votre disposition par téléphone et e-mail</h3>
                            <h5>Contactez-nous pour prendre rendez-vous et obtenir un devis</h5>
                        </div>: <div className="paragraph-title">
                            <h2>Nous sommes à votre disposition par téléphone et e-mail</h2>
                            <h4>Contactez-nous pour prendre rendez-vous et obtenir un devis</h4>
                        </div>}
                        <div className="tm-80">
                            <ListBlock src={"icons/phone_support.png"}
                                       title={"Par téléphone : 06.52.22.25.76"}
                                       text={<div>
                                       </div>}
                            />
                            <ListBlock src={"icons/mail_icon.png"}
                                    //    title={"Par e-mail : contact@jcacom.fr"}
                                        title={"Par e-mail : contact.jcacom@gmail.com"}
                                       text={<div>
                                       </div>}
                            />
                        </div>
                    </fragment>
                } />
            </div>
        )
    }

});