import React              from 'react';
import ReactDOM           from 'react-dom';

import './idGenerator';
import Router             from "./components/Router";
import * as serviceWorker from "./serviceWorker";

import 'typeface-roboto';
import 'materialize-css/dist/css/materialize.css';
import 'material-icons/iconfont/material-icons.css';
import 'material-icons/css/material-icons.css';

ReactDOM.render(
<Router/>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();