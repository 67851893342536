import React from "react";
import * as $      from 'jquery';

export default class extends React.Component {

    state = {selected_view: this.props.selected || (this.props.views && this.props.views.length > 0 ? this.props.views[0].name : "none")};

    componentDidUpdate(){
        if(this.props.selected !== this.state.selected_view){
            this.setState({selected_view: this.props.selected}, function(){
                $("html").scrollTop(0);
            })
        }
    }

    render(){
        let views = this.props.views, transition = this.props.transition, selected = this.state.selected_view, display = [];
        if(views){
            for(let view of views){
                let className = "view ";
                if(transition === "opacity"){
                    className += "opacity-transition ";
                }
                if(view.name === selected){
                    className += "show-view";
                }else{
                    className += "hide-view"
                }
                display.push(<div className={className}>
                    {view.display}
                </div>)
            }
        }

        return (
            <div className="view-handler" >
                {display}
            </div>
        )
    }
}