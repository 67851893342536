import '../styles/app.css';

import createReactClass  from 'create-react-class';
import React, {Fragment} from 'react';

import Navbar2 from './react-components/Navbar2';
import ViewHandler from "./react-components/ViewHandler";

import HomeView from "./HomeView";
import LandingPageView from "./LandingPageView";
import ECommerceView from "./ECommerceView";
import RestaurantView from "./RestaurantView";
import ContactView from "./ContactView";
import * as $      from 'jquery';
import M from "materialize-css";

let iconSrc = require.context('../images', true);

export default createReactClass({
    getInitialState: function() {
        return {current_view: "home"};
    },
    resizeTimeout: null,
    componentDidMount: function(){
        let elems = document.querySelectorAll('.parallax');
        let instances = M.Parallax.init(elems);
        let _this = this;
        window.addEventListener("resize", function(event) {
            clearTimeout(_this.resizeTimeout);
            _this.resizeTimeout = setTimeout(function(){
                _this.setState({});
            }, 100);
        })
    },
    onViewSelect: function(view){
        if(view === this.state.current_view)
            return;
        this.setState({current_view: view});
    },
    refresh: function() {
        this.forceUpdate();
    },
    render: function() {
        /*let menus = [
            {title: "Acceuil", name: "home"},
            {title: "Site vitrine", name: "landing-page"},
            {title: "E-Commerce", name: "e-commerce"},
            {title: "Restauration", name: "restaurant"},
            {title: "Contact", name: "contact"}
        ];
        let views = [
            {name: "home", display: <HomeView onContactClick={this.onViewSelect.bind(null, "contact")} />},
            {name: "landing-page", display: <LandingPageView />},
            {name: "e-commerce", display: <ECommerceView />},
            {name: "restaurant", display: <RestaurantView />},
            {name: "contact", display: <ContactView />}
        ];*/
        let menus = [
            {title: "Accueil", name: "home"},
            {title: "Site vitrine", name: "landing-page"},
            {title: "E-Commerce / Restauration", name: "e-commerce"},
            {title: "Contact", name: "contact"}
        ];
        let views = [
            {name: "home", display: <HomeView onContactClick={this.onViewSelect.bind(null, "contact")} />},
            {name: "landing-page", display: <LandingPageView />},
            {name: "e-commerce", display: <ECommerceView />},
            {name: "contact", display: <ContactView />}
        ];
        return (
            <Fragment>
                <Navbar2 title={<span>
                    <img src={iconSrc("./JCA_LOGO_2_white_bg.png")} height="50" style={logo_style} className="z-depth-2" />
                    <span>JCA COM</span>
                </span>}
                         menus={menus} backgroundColor="#01579b" menuStyle={menu_item_style} className="z-depth-3 app-navbar" fixed="true"
                         onTitleClick={this.onViewSelect.bind(null, "home")} onMenuClick={this.onViewSelect} selected={this.state.current_view} />
                <ViewHandler selected={this.state.current_view} views={views} transition="opacity" />
            </Fragment>
        )

    }
});

let logo_style = {
    verticalAlign: "middle",
    marginRight: "10px",
    backgroundColor: "white",
    borderRadius: "10px",
    position: "relative",
    bottom: "2px"
};

let menu_item_style = {
    color: "#FFF",
    fontWeight: "bold",
    padding: "0 40px"
};

window.appViewSelect = null;

window.$ = $;

