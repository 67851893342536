/**
 * Created by alexandre on 01/06/16.
 */

var currentID = 0;

window.generateID = function(){
    currentID++;
    return ""+currentID;
};

window.smallWidthDisplay = function(){
   return window.screen.width <= 750;
};

window.isTouchDevice = ((window.navigator.msPointerEnabled && navigator.msMaxTouchPoints > 0) ||
(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
  ? true : false;

function getPaddingPx(selector){
  return {top:parseFloat(selector.css("padding-top").replace("px","")),
    bottom:parseFloat(selector.css("padding-bottom").replace("px","")),
    left:parseFloat(selector.css("padding-left").replace("px","")),
    right:parseFloat(selector.css("padding-right").replace("px",""))}
}

function getMarginPx(selector){
  return {top:parseFloat(selector.css("margin-top").replace("px","")),
    bottom:parseFloat(selector.css("margin-bottom").replace("px","")),
    left:parseFloat(selector.css("margin-left").replace("px","")),
    right:parseFloat(selector.css("margin-right").replace("px",""))}
}

function getBorderWidthPx(selector){
  return {top:parseFloat(selector.css("border-top-width").replace("px","")),
    bottom:parseFloat(selector.css("border-bottom-width").replace("px","")),
    left:parseFloat(selector.css("border-left-width").replace("px","")),
    right:parseFloat(selector.css("border-right-width").replace("px",""))}
}

window.getSizePx = function(selector){
  return{width:parseFloat(selector.css("width").replace("px","")),
    height:parseFloat(selector.css("height").replace("px",""))}
};
