import createReactClass  from 'create-react-class';
import React, {Fragment} from 'react';

import Parallax from "./react-components/Parallax";
import Button from "./react-components/Button";

export default createReactClass({

    render: function(){
        return (
            <div>
                <Parallax src={"geometric.jpg"} height={300}  content={<div style={{marginTop: "100px"}} >
                    <Button className="z-depth-1" color="green" text="Nous contacter" onClick={this.props.onContactClick}
                            style={{fontSize: "32px", padding: "20px 40px", height: "auto"}}/>
                </div>} />
            </div>
        )
    }

});