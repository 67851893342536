import createReactClass  from 'create-react-class';
import React, {Fragment} from 'react';

import Parallax from "./react-components/Parallax";
import ParallaxSection from "./react-components/ParallaxSection";

export default createReactClass({

    render: function(){
        return (
            <div>
                <Parallax src={"colour.jpg"} content={<h1>TEST</h1>} />

                <ParallaxSection content={
                    <div>
                        <h5>Restauration</h5>
                        <p>BLABLABLABLALBLALBLABLALljksdfjksdhfksdf</p>
                        <p>BLABLABLABLALBLALBLABLALljksdfjksdhfksdf</p>
                    </div>
                } />
                <Parallax src={"geometric.jpg"}  />
                <ParallaxSection content={
                    <div>
                        <h5>Nos offres</h5>
                        <p>BLABLABLABLALBLALBLABLALljksdfjksdhfksdf</p>
                        <p>BLABLABLABLALBLALBLABLALljksdfjksdhfksdf</p>
                    </div>
                } />
                <Parallax src={"particles.jpg"}  />
                <ParallaxSection content={
                    <div>
                        <h5>Contact</h5>
                        <p>BLABLABLABLALBLALBLABLALljksdfjksdhfksdf</p>
                        <p>BLABLABLABLALBLALBLABLALljksdfjksdhfksdf</p>
                    </div>
                } />
            </div>
        )
    }

});