import React from "react";

export default class extends React.Component {

    render(){
        var nav_style = {}, item_style = {};
        if(this.props.fixed)
            nav_style.position = "fixed";
        if(this.props.backgroundColor)
            nav_style.backgroundColor = this.props.backgroundColor;
        if(this.props.textColor){
            nav_style.color = this.props.textColor;
            item_style.color = this.props.textColor;
        }
        if(this.props.menuStyle){
            for(let prop in this.props.menuStyle){
                item_style[prop] = this.props.menuStyle[prop];
            }
        }
        let display = [];
        for(let menu of this.props.menus){
            let item_class;
            if(this.props.selected === menu.name)
                item_class = "active";
            display.push(<li className={item_class}><a style={item_style} onClick={this.props.onMenuClick.bind(null, menu.name)}>{menu.title}</a></li>);
        }
        return (
            <nav className={this.props.className} style={nav_style} >
                <div className="nav-wrapper">
                    <a className="lm brand-logo" style={{cursor: "pointer"}} onClick={this.props.onTitleClick}>{this.props.title}</a>
                    <ul id="nav-mobile" className="right hide-on-med-and-down">
                        {display}
                    </ul>
                </div>
            </nav>
        )
    }
}