import React from "react";

export default class extends React.Component {
    render(){
        var color = this.props.color ? this.props.color : "white";
        return (
            <div className={"z-depth-2 section "+color}>
                <div className="row container">
                    {this.props.content}
                </div>
            </div>
        )
    }
}