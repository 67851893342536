import createReactClass  from 'create-react-class';
import React, {Fragment} from 'react';

import Parallax from "./react-components/Parallax";
import ParallaxSection from "./react-components/ParallaxSection";
import ListBlock from "./ListBlock";

import ContactFooter from "./ContactFooter";

export default createReactClass({

    render: function(){
        return (
            <div>
                <Parallax src={"bg_1.jpg"} content={<div style={{color: "white", marginTop: "200px"}} >
                    <span className="title-background-black">
                        <h1 className="m-20">Site vitrine</h1>
                        <h2 className="m-20">Votre marketing en ligne sur-mesure</h2>
                    </span>
                </div>} />

                <ParallaxSection content={
                    <fragment>
                        <div className="paragraph-title">
                            <h2>Nos solutions</h2>
                            <h4>Un grand panel d'options pour répondre à vos besoins</h4>
                        </div>
                        <div className="tm-80">
                            <ListBlock src={"icons/landing_page_icon.png"}
                                       icon_bg_type="square" icon_bg_color="#c5cae9 "
                                       title={"Web & Mobile : Un design à votre image"}
                                       text={<div>
                                           <div className="bm-10">Nous sommes à votre écoute pour présenter au mieux votre business et vos services.</div>
                                           <div>Web, Android & iOS : votre communication digitale sur tous les fronts.</div>
                                       </div>}
                            />
                            <ListBlock src={"icons/multiple_site.png"}
                                       icon_bg_type="circle" icon_bg_color="#c5cae9 "
                                       text={<div>
                                           <ul className="custom-list">
                                               <li>Responsive web : un design qui s'adapte à tous les formats (ordinateur, tablette et mobile)</li>
                                               <li>Un nom de domaine de votre choix</li>
                                               <li>Une adresse e-mail professionnelle associée au nom de domaine</li>
                                               <li>Référencement Google et Google Maps (gestion campagne pug Google en option)</li>
                                               <li>Mise en place outil de statistiques des visites de votre site</li>
                                           </ul>
                                       </div>}
                            />
                            {false && <ListBlock src={"icons/discount_white.png"}
                                       icon_bg_type="square" icon_bg_color="#c5cae9 "
                                       title={"Tarifs"}
                                       text={<div>
                                           <div className=""><span className="bold">Création : 499 €</span><span> H.T.</span></div>
                                           <div style={{marginTop: "5px"}}>A régler à la livraison du site une fois que vous êtes satisfait du résultat.</div>
                                           <div className="tm"><span className="bold">Hébergement/maintenance : 79 €</span><span> H.T. / mois</span></div>
                                           <div style={{marginTop: "5px"}}>Comprend les frais d'hébergement du site, la location du nom de domaine, l'adresse e-mail pro, la maintenance et le support (y compris les demandes de modification de contenus).</div>
                                           <div>Sans engagement de durée.</div>
                                       </div>}
                            />}
                        </div>
                    </fragment>
                } />
                <ContactFooter onContactClick={this.props.onContactClick} />
            </div>
        )
    }

});