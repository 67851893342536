import React, {Fragment} from 'react';
import createReactClass from "create-react-class";

let iconSrc = require.context('../images', true);

export default createReactClass({

    render: function(){
        let icon_bg_style = Object.assign({}, icon_background_style);
        let icon_size = parseInt(this.props.iconHeight || 140), bg_padding = parseInt(this.props.icon_bg_padding || 30);;
        if(window.smallWidthDisplay()){
            icon_size = Math.round(icon_size * 0.8);
            bg_padding = Math.round(bg_padding * 0.66);
        }
        let bg_size = icon_size + 2 * bg_padding;
        if(this.props.icon_bg_color){
            icon_bg_style.backgroundColor = this.props.icon_bg_color;
        }
        if(this.props.icon_bg_type === "circle"){
            icon_bg_style.borderRadius = "50%";
        }else if(this.props.icon_bg_type === "square"){
            icon_bg_style.borderRadius = "10px";
        }
        icon_bg_style.width = bg_size + "px";
        icon_bg_style.height = bg_size + "px";
        return (
            window.smallWidthDisplay() ?

                <div style={{borderTop: "1px solid #e0e0e0"}} className={"tm-30 bm-30"}>
                    <div className="center-align">
                        <h4 className="inline-block bold">{this.props.title}</h4>
                    </div>
                    <div style={{textAlign: "center"}}>
                        <div style={icon_bg_style} className="valign-wrapper">
                            <img src={iconSrc("./" + this.props.src)} style={{margin: "auto", maxHeight: icon_size + "px", maxWidth: icon_size + "px"}} />
                        </div>
                    </div>
                    <div>
                        <h5>{this.props.text}</h5>
                    </div>
                </div>

                :

                <div className={"tm-50 bm-50 valign-wrapper row"}>
                    <div style={{ display: "inline-block", width: "33%", textAlign: "center"}}>
                        <div style={icon_bg_style} className="valign-wrapper">
                            <img src={iconSrc("./" + this.props.src)} style={{margin: "auto", maxHeight: icon_size + "px", maxWidth: icon_size + "px"}} />
                        </div>
                    </div>
                    <div style={{display: "inline-block", width: "66%"}}>
                        <h4 className="bold">{this.props.title}</h4>
                        <h5>{this.props.text}</h5>
                    </div>
                </div>


        )

    }

});

let icon_background_style = {
    textAlign: "center",
    margin: "auto"
};