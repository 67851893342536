import React                      from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';

import App         from './AppMain';

const Router = () => {

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path='/' component={App}/>
                <Redirect from='*' to='/'/>
            </Switch>
        </BrowserRouter>
    );
};

export default Router;
